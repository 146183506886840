<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">OJT Assessment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <div class="d-flex">
                <label class="col-form-label me-1">Program Name</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="program"
                    :options="programs"
                    :clearable="false"
                    :selectable="(options) => isSelectableProgram(options)"
                    @option:selected="
                      getBatchByProgram(program)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <label class="col-form-label me-1 ms-3">Batch</label>
              <div style="width: 150px">
                <v-select
                  label="batch_no"
                  v-model="batch"
                  :options="batches"
                  :clearable="false"
                  :selectable="(options) => options.id != batch.id"
                  @option:selected="
                    getEmployeeByBatch(program.id, batch.id)
                  "
                >
                </v-select>
              </div>
              <label class="col-form-label me-1 ms-3">Month</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="month"
                  :options="allMonths"
                  :clearable="false"
                  :selectable="(options) => options.id != month.id"
                  @option:selected="
                    fetchData(program, batch, month, assessor)
                  "
                >
                </v-select>
              </div>
              <label class="col-form-label me-1 ms-3">Assessor</label>
              <div style="width: 150px">
                <v-select
                  label="assessor_name"
                  v-model="assessor"
                  :options="assessors"
                  :clearable="false"
                  :selectable="(options) => options.assessor_id != assessor.assessor_id"
                  @option:selected="
                    fetchData(program, batch, month, assessor)
                  "
                >
                </v-select>
              </div>
              <div @click="refreshData" class="icon-css">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <!-- <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="evaluating-weight-list-datatable"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 150px">No.</th>
                    <th style="width: 200px">Employee ID</th>
                    <th style="width: 200px">Employee Name</th>
                    <th style="width: 200px">Program Name</th>
                    <th style="width: 200px">Batch</th>
                    <th style="width: 200px">Program Level</th>
                    <th style="width: 200px">Final Result</th>
                    <th class="d-none">Month</th>
                    <th class="d-none">Assessor</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in ojtAssessments" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.employee_id }}</td>
                    <td>{{ item.employee_name }}</td>
                    <td>{{ item.program_name }}</td>
                    <td>{{ item.program_batch_no }}</td>
                    <td>{{ item.level }}</td>
                    <td>
                      {{
                        item.hrd_score +
                        "     ( " +
                        (item.hrd_suggestion ?? "") +
                        " )"
                      }}
                    </td>
                    <td class="d-none">{{ getMonth(item.created_at) }}</td>
                    <td class="d-none">{{ item.assessor_employee_name }}</td>
                  </tr>
                </tbody>
              </table> -->
              <DataTable2 
                v-if="tableData.length > 0 && !loadingTable"
                :data="tableData"
                :columns="columns"
                :table="title"
                :loading="loadingTable"
                :totalRecords="totalRecords"
                :currentPage="currentPage"
                :pageSize="pageSize"
                @update:pagination="handlePagination"
              >
                  <template v-slot:column-hrd_score="{ row }">
                    {{ row.hrd_score }} ( {{ getHrdSuggesstion(row.hrd_score) }} )
                  </template>
              </DataTable2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable2 from '@/components/shared/DataTable2.vue';

import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import { months } from "../../../utlis/months";
import { hrd_suggestions } from "../../../utlis/hrd_suggestions";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    DataTable2
  },
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      batches: [],
      allMonths: [],
      assessors: [],
      assessor: "",
      month: "",
      program: "",
      batch: "",
      title: "OJT Assessment Report Lists",
      tableData: [],
      columns: [
        { data: 'employee_id', title: 'Employee ID' },
        { data: 'employee_name', title: 'Employee Name' },
        { data: 'program_name', title: 'Program Name' },
        { data: 'program_batch_no', title: 'Batch' },
        { data: 'level', title: 'Program Level' },
        { data: 'hrd_score', title: 'Final Result' },
        { data: 'assessor_employee_name', title: 'Assessor Name' },
      ],
      totalRecords: 0,
      currentPage: 1,
      pageSize: 10,
      // searchQuery: '',
      ignoreNextEmit: false,
      headers: [
        "No.",
        "Employee ID",
        "Employee Name",
        "Program Name",
        "Batch",
        "Program Level",
        "Final Result",
        'Assessor Name'
      ],
      forExportingData: null,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      loadingTable: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
       
    };
  },

  methods: {
    async getBatchByProgram(program) {
      this.batch = "";
      this.assessor = "";
      this.employee = "";
      this.employeeLists = [];
      this.assessorList = [];
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program.id}`
        )
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getEmployeeByBatch(programId, batchId) {
      this.assessor = "";
      this.employeeLists = [];
      this.employee = "";
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/get-assessor-list/${programId}/${batchId}`
        )
        .then((response) => {
          this.assessors = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Employee!");
        });
    },
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    isSelectableProgram(options) {
      if (!this.program || !options) return true;
      return this.program.id != options.id;
    },
    async getBatch() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/program-batches/list`)
        .then((response) => {
          this.batches = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async fetchTableData({ currentPage, pageSize }) {
      this.loading = true;
      this.loadingTable = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/ojt-assessment-list-report`, {
          params: {
            page: currentPage,
            per_page: pageSize,
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            month: this.month.id ?? '',
            assessor_employee_id: this.assessor.emp_id ?? '',
            // search: this.searchQuery,
          },
        });
        this.tableData = response.data.data;
        this.totalRecords = response.data.total;
        this.currentPage = currentPage;
        this.pageSize = pageSize;
      } catch (error) {
        this.toast.error("Something went wrong.");
        console.error('Error fetching table data:', error);
      } finally {
        this.loading = false;
        this.loadingTable = false;
        this.$Progress.finish();
      }
    },
    async getWithoutPaginate(){
      this.loading = true;
      this.$Progress.start();
      try {
        const response = await axios.get(`${this.baseUrl}admin/v2/ojt-assessment-list-report`, {
          params: {
            program_id: this.program.id ?? '',
            batch_id: this.batch.id ?? '',
            month: this.month.id ?? '',
            assessor_employee_id: this.assessor.emp_id ?? '',
          },
        });
        if(response){
          return response.data.data;
        }
      } catch (error) {
        this.toast.error("Something went wrong.");
      } finally {
        this.loading = false;
        this.$Progress.finish();
      }

      return false;
    },
    fetchData() {
      this.currentPage = 1;
      this.pageSize = 10;
      // this.searchQuery = '';
      this.fetchTableData({ currentPage: this.currentPage, pageSize: this.pageSize });
    },
    handlePagination({ currentPage, pageSize }) {
      if (this.ignoreNextEmit) {
        this.ignoreNextEmit = false;
        return;
      }
      if (this.pageSize !== pageSize) {
        this.currentPage = 1; // Reset to first page if page size changes
      }
      console.log(this.currentPage, this.pageSize);
      if (this.currentPage !== currentPage || this.pageSize !== pageSize) {
        this.currentPage = currentPage;
        this.pageSize = pageSize;
        this.fetchTableData({ currentPage, pageSize });
      }
    },
    getHrdSuggesstion(hrd_score){
      return hrd_suggestions.filter((item) => {
          return hrd_score <= item.highest_point && hrd_score >= item.lowest_point
        })[0].name;

    },
    refreshData() {
      this.program = "";
      this.batch = "";
      this.month = "";
      this.assessor = "";
      this.fetchData();
    },
    async exportPdfClick() {
      const apiData = await this.getWithoutPaginate();
      if(apiData && apiData.length > 0){
        this.pdfLoading = true;
        const sheetData = apiData.map((item, index) => {
          let row = [];
          row[0] = index + 1;
          row[1] = item.employee_id;
          row[2] = item.employee_name;
          row[3] = item.program_name;
          row[4] = item.program_batch_no;
          row[5] = item.level;
          row[6] = `${ item.hrd_score } ( ${ this.getHrdSuggesstion(item.hrd_score) } )`;
          row[7] = item.assessor_employee_name;
          return row;
        });
        exportPdf(this.headers, sheetData, "OJT-Assessment-Report");
        this.pdfLoading = false;
      }else{
        return this.toast.error("There is no data to export!");
      }
      
    },
    async exportExcelClick() {
      const apiData = await this.getWithoutPaginate();
      if (apiData && apiData.length > 0) {
        this.excelLoading = true;
        
        // Add headers as the first row
        const sheetData = [this.headers];

        // Add data rows
        apiData.forEach((item, index) => {
          sheetData.push([
            index + 1,
            item.employee_id,
            item.employee_name,
            item.program_name,
            item.program_batch_no,
            item.level,
            `${ item.hrd_score } ( ${ this.getHrdSuggesstion(item.hrd_score) } )`,
            item.assessor_employee_name,
          ]);
        });

        exportExcel(sheetData, "OJT-Assessment-Report");
        this.excelLoading = false;
      } else {
        return this.toast.error("There is no data to export!");
      }
    },
  },
  created() {
    this.allMonths = months;
  //   this.getBatch();
  //   this.fetchData();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
